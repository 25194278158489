import React from "react";

import RecipeCard from "@components/global/recipeCard";
import { useOurPicksQuery } from "@src/query";
import { useStore } from "@state/store";
import { colorsRGB } from "@util/constants";
import { Container, GridContainer, H3 } from "@util/standard";

function Overview() {
  const { user } = useStore();
  const recipes = useOurPicksQuery();

  if (recipes == null || user == null) {
    return null;
  }

  return (
    <Container width="100%" backgroundRGBColor={colorsRGB.darkerGreen(0.05)}>
      <Container width="100%" margin="12% 15%" tabletMargin="60px auto" tabletWidth="80%" flexDirection="column">
        {user.firstName && <H3>{user.firstName}, we thought you might like these</H3>}
        {recipes.recipeRefs && recipes.recipeRefs && (
          <GridContainer repeat={2} columnGap="15px" rowGap={15}>
            {recipes.recipeRefs.map(recipe => {
              if (recipe == null) {
                return null;
              }
              return <RecipeCard recipeId={recipe._id} data={recipe} />;
            })}
          </GridContainer>
        )}
      </Container>
    </Container>
  );
}

export default Overview;
