import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Dropdown } from "react-dropdown-now";

import { Button } from "@components/global";
import { useStore } from "@state/store";
import { colorsRGB, icons, TABLET_BREAKPOINT } from "@util/constants";
import { formatDateDropdown, setUser, setUserDetails } from "@util/helper";
import { Container, H3, P } from "@util/standard";
import { useCheckScreenWidth } from "@util/hooks";

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colorsRGB.black(0.4)};

  font-size: 15px;
  padding-bottom: 10px;

  :focus-visible {
    outline: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  color: black;
  margin-right: 10px;

  .rdn-control {
    width: 100%;
    border: 1px solid black;
    padding-left: 20px;
    background: transparent;
  }

  .is-selected {
    width: 100%;
    background: transparent;
  }

  .rdn-drop {
    overflow-y: scroll;
    max-height: none;
    border: 1px solid black;
    z-index: 4;
    width: 100%;
    height: 200px;
  }

  .rdn-control-arrow-icon {
    border-style: none;
    background: url(${icons.arrow});
    width: 14px;
    height: 9px;
    margin-right: 5px;
  }
  .rdn-drop-menu-option {
    color: black;
    padding: 0 0 0 20px;
    font-weight: 600 !important;

    &:hover {
      background: transparent;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 10px 0px;
    .rnd-control-placeholder {
    }
  }
`;

function MyDetails() {
  const { user, savedBlogs, savedRecipes } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();
  const [formData, setFormData] = useState({
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: "",
    password: "",
    dob: {
      day: user?.dob?.day ?? "",
      month: user?.dob?.month ?? "",
      year: user?.dob?.year ?? "",
    },
  });

  if (user == null) {
    return null;
  }

  const handleSubmit = () => {
    if (formData.firstName === "") {
      toast.error("First Name required");
      return;
    }
    if (formData.lastName === "") {
      toast.error("Last Name required");
      return;
    }
    const newUserData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      savedRecipes: savedRecipes ?? [],
      savedBlogs: savedBlogs ?? [],
      email: user.email,
      name: user.name,
      profilePic: user.profilePic,
      uid: user.uid,
      dob: formData.dob,
    };
    setUserDetails(newUserData);
    setUser(user.uid);
    toast.success("Details updated");
  };

  return (
    <Container width="100%" backgroundRGBColor={colorsRGB.darkerGreen(0.05)}>
      <Container margin="12% 15%" tabletMargin="60px auto" tabletWidth="80%" flexDirection="column">
        <H3 margin="0">My Details</H3>
        <P margin="15px 0 60px 0"> Feel free to edit any of your details below so that your account is up to date.</P>

        <Input
          placeholder="First Name"
          defaultValue={user.firstName}
          onChange={e => setFormData({ ...formData, firstName: e.target.value })}
        />
        <P fontSize={13} tabletFontSize={13} margin="5px 0 40px 0" opacity={0.5}>
          First Name
        </P>
        <Input
          placeholder="Last Name"
          defaultValue={user.lastName}
          onChange={e => setFormData({ ...formData, lastName: e.target.value })}
        />
        <P fontSize={13} tabletFontSize={13} margin="5px 0 40px 0" opacity={0.5}>
          Last Name
        </P>
        <Container isMobileColumn>
          <StyledDropdown
            placeholder={user.dob ? user.dob.day : "Day"}
            options={formatDateDropdown().days}
            value={user.dob?.day ?? "1"}
            onChange={option => setFormData({ ...formData, dob: { ...formData.dob, day: option.value as string } })}
          />
          <StyledDropdown
            placeholder={user.dob ? user.dob.month : "Month"}
            options={formatDateDropdown().months}
            value={user.dob?.month ?? "January"}
            onChange={option => setFormData({ ...formData, dob: { ...formData.dob, month: option.value as string } })}
          />
          <StyledDropdown
            placeholder={user.dob ? user.dob.year : "Year"}
            options={formatDateDropdown().years}
            value={user.dob?.year ?? "2022"}
            onChange={option => setFormData({ ...formData, dob: { ...formData.dob, year: option.value as string } })}
          />
        </Container>
        <P fontSize={13} tabletFontSize={13} margin="5px 0 40px 0" opacity={0.5}>
          Date of Birth
        </P>
        <Container justifyContent="flex-end" margin="40px 0 0 0">
          <Button text="Save changes" theme="block" width="194px" onClick={() => handleSubmit()} />
        </Container>
      </Container>
    </Container>
  );
}

export default MyDetails;
