import { FirebaseProps, withFirebase } from "@lib/firebase";
import { useStore } from "@state/store";
import { colors, colorsRGB, icons, TABLET_BREAKPOINT } from "@util/constants";
import { isBrowser, setUser } from "@util/helper";
import { useCheckScreenWidth, usePrevious } from "@util/hooks";
import { Container, H2, P, A } from "@util/standard";
import { navigate } from "gatsby";
import firebase from "gatsby-plugin-firebase";
import { isAbsolute } from "path/posix";

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";
import MyDetails from "./myDetails";
import Overview from "./overview";

const ProfileImg = styled.img<{ rounded?: boolean; }>`
  width: 91px;
  height: 91px;
  margin-right: 25px;

  ${({ rounded }) => rounded && `border-radius: 50%;`}

  @media only screen and (max-width:${TABLET_BREAKPOINT}px) {
    width: 67px;
    height: 67px;
    margin-bottom: 15px;
  }
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const SavedContainer = styled(Container)`
  width: 45%;
  margin: 0 auto 45px auto;
  border: 1px solid ${colorsRGB.white(0.4)};
  padding: 14px 25px;
  border-radius: 2px;
  cursor: pointer;
`;

const MenuButton = styled(Container) <{ isSelected?: boolean; isLast?: boolean; isFirst?: boolean; }>`
  background: ${colorsRGB.darkerGreen(0.05)};
  width: 100%;
  cursor: pointer;
  border-top: 1px solid ${colorsRGB.darkerGreen(0.2)};
  ${({ isFirst }) => isFirst && `border-top: none;`};
  ${({ isSelected }) =>
    isSelected &&
    `
  border-left: 3px solid ${colors.darkerGreen}; 
  p {font-weight: 700;}
  width: calc(100% - 3px);
  `};

  p {
    margin: 21px 0 21px 30px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-bottom: 3px solid ${colorsRGB.darkerGreen(0.2)};
    border-left: none;

    ${({ isSelected }) =>
    isSelected &&
    `
  border-bottom: 3px solid ${colors.darkerGreen}; 
  p {font-weight: 700;}
  `};

    p {
      width: max-content;
      margin: 16px auto;
    }
  }
`;

function SignOut(props: FirebaseProps) {
  const { isLaptopWidth } = useCheckScreenWidth();

  if (props.firebase == null) {
    return null;
  }
  const auth = props.firebase.auth();
  const handleSignOut = () => {
    auth.signOut();
    useStore.setState({ user: undefined, isLoggedIn: false, savedBlogs: [], savedRecipes: [] });
    navigate("/");
  };
  return (
    auth.currentUser && (
      <MenuButton onClick={() => handleSignOut()}>
        <A underlined margin={!isLaptopWidth ? "16px auto" : "21px 0 21px 30px"}>
          Sign out
        </A>
      </MenuButton>
    )
  );
}
const SignOutWithFirebase = withFirebase(SignOut);

function AccountMenu() {
  if (!isBrowser()) {
    return null;
  }
  if (firebase == null || withFirebase == null) {
    return null;
  }
  const auth = firebase.auth();

  const { user } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();
  const [currentMenuOpen, setCurrentMenuOpen] = useState("overview");

  const [authUser] = useAuthState(auth);
  const prevAuthUser = usePrevious(authUser);

  useEffect(() => {
    if (prevAuthUser && authUser == null) {
      useStore.setState({ user: undefined, savedRecipes: [] });
    }
    if (!user) {
      navigate("/");
    }

    if (authUser) {
      setUser(authUser.uid);
    }
  }, [authUser]);

  return (
    <Container width="88%" tabletWidth="100%" margin="210px auto" flexDirection="column" mobileMargin="210px auto 0px auto">
      <Container
        flexDirection={!isLaptopWidth ? "column" : "row"}
        width="max-content"
        tabletWidth="80%"
        tabletMargin="auto"
      >
        <ProfileImg rounded={user?.profilePic ? true : false} src={user?.profilePic ? user.profilePic : icons.login} />
        <Container flexDirection="column" margin="0 auto 60px auto" tabletMargin="0 0 60px 0">
          <P noMargin>WELCOME BACK</P>
          {user && (
            <H2 color="darkerGreen" margin="5px 0 0 0">
              {user?.firstName + " " + user.lastName}
            </H2>
          )}
        </Container>
      </Container>
      <Container tabletFlexDirection="column">
        <Container width="30%" tabletWidth="100%" flexDirection="column">
          {!isLaptopWidth && (
            <Container width="100%" margin="10px 0 0 0" backgroundColor="mossGreen" flexDirection="column">
              <P color="white" textAlign="center" width="64%" margin="45px auto 15px auto">
                VIEW YOUR SAVE RECIPES AND ARTICLES
              </P>
              <A href="/my-saved">
                <SavedContainer width="45%">
                  <Container margin="auto">
                    <Icon src={icons.unSaved} />
                    <P color="white" noMargin>
                      My Saved List
                    </P>
                  </Container>
                </SavedContainer>
              </A>
            </Container>
          )}
          <Container flexDirection="column" tabletFlexDirection="row" overflow={!isLaptopWidth ? "scroll" : "hidden"}>
            <MenuButton
              isSelected={currentMenuOpen === "overview"}
              isFirst
              onClick={() => setCurrentMenuOpen("overview")}
            >
              <P>Overview</P>
            </MenuButton>
            {/* <MenuButton isSelected={currentMenuOpen === "orders"} onClick={() => setCurrentMenuOpen("orders")}>
              <P>My Orders</P>
            </MenuButton> */}
            <MenuButton isSelected={currentMenuOpen === "details"} onClick={() => setCurrentMenuOpen("details")}>
              <P>My details</P>
            </MenuButton>
            {/* <MenuButton isSelected={currentMenuOpen === "contact"} onClick={() => setCurrentMenuOpen("contact")}>
              <P>Contact Preferences</P>
            </MenuButton> */}
            {authUser && <SignOutWithFirebase />}
          </Container>
          {isLaptopWidth && (
            <Container width="100%" margin="10px 0 0 0" backgroundColor="mossGreen" flexDirection="column">
              <P color="white" textAlign="center" width="64%" margin="45px auto 15px auto">
                VIEW YOUR SAVE RECIPES AND ARTICLES
              </P>
              <A href="/my-saved">
                <SavedContainer width="45%">
                  <Container margin="auto">
                    <Icon src={icons.unSaved} />
                    <P color="white" noMargin>
                      My Saved List
                    </P>
                  </Container>
                </SavedContainer>
              </A>
            </Container>
          )}
        </Container>
        <Container width="70%" tabletWidth="100%" height="100%" margin="0 0 0 15px" tabletMargin="0">
          {currentMenuOpen === "overview" && <Overview />}
          {currentMenuOpen === "details" && <MyDetails />}
        </Container>
      </Container>
    </Container>
  );
}

export default AccountMenu;
