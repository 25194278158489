import AccountMenu from "@components/account/accountMenu";
import { Button } from "@components/global";
import Layout from "@shared/layout";
import { useStore } from "@state/store";
import { createCheckout, getAllProducts } from "@util/shopifyHelper";
import { A } from "@util/standard";
import { CheckoutLineItemInput, Shopify } from "@util/types";
import React, { useCallback, useEffect } from "react";

function Account() {
  return (
    <>
      <Layout>
        <AccountMenu />
      </Layout>
    </>
  );
}

export default Account;
