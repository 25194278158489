import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SanityRecipe } from "@graphql-types";
import { Container, Gradient, H4, A, P, LinkContainer } from "@util/standard";
import { Image } from ".";
import { gradients, icons, TABLET_BREAKPOINT } from "@util/constants";
import { useStore } from "@state/store";
import { handleSaveRecipe } from "@util/helper";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityRecipe;
  margin?: string;
  recipeId: string;
}

const Icon = styled.img`
  margin: auto 5px auto auto;
  width: 16px;
  height: 16px;
`;

const TimeDetails = styled(Container)<{ hoverOn?: boolean }>`
  margin: 20px 0 30px 0;
  ${({ hoverOn }) =>
    hoverOn ? `height: 16px; margin: 20px 0 30px 0; opacity: 1;` : `height: 0px; margin: 0 0 30px 0; opacity: 0;`};
  transition: all 0.2s;
  overflow: visible;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 15px 0 30px 0;
  }
`;

const SaveIcon = styled.img`
  width: 14px;
  height: 20px;
  margin: auto;
`;

function RecipeCard({ data, recipeId, margin }: Props) {
  const [hoverOn, setHoverOn] = useState(false);
  const [isSaved, setIsSaved] = useState<boolean>();
  const { user, savedRecipes, isLoggedIn } = useStore();

  if (data == null) {
    return null;
  }

  const recipeMatch = () => {
    const recipeMatches = savedRecipes?.filter(recipe => recipe === recipeId);
    recipeMatches && recipeMatches.length > 0 ? setIsSaved(true) : setIsSaved(false);
  };

  const checkSaved = () => {
    if (!isLoggedIn) {
      useStore.setState({ authMenuOpen: true });
    }
    if (user == null || recipeId == null || user.uid == null) {
      return null;
    }
    handleSaveRecipe({
      id: recipeId,
      savedRecipes: savedRecipes ? savedRecipes : [],
      isUserSignedIn: true,
      userUId: user.uid,
    });
    return;
  };
  const { isLaptopWidth, isMobileWidth } = useCheckScreenWidth();

  useEffect(() => {
    recipeMatch();
  });

  return (
    <Container width="100%" position="relative" margin={margin} flexDirection="column">
      <Container
        onClick={checkSaved}
        cursor="pointer"
        backgroundColor={isSaved ? "wanakaBlue" : "pink"}
        width="50px"
        height="50px"
        position="absolute"
        top="0px"
        right="0px"
        zIndex={3}
      >
        <SaveIcon src={isSaved ? icons.saved : icons.unSaved} />
      </Container>
      <LinkContainer to={`/recipes/${data?.slug?.current}`} margin="0" width="100%" height="100%" maxHeight="400px">
        <Container
          width="100%"
          height="100%"
          position="relative"
          onMouseEnter={() => setHoverOn(true)}
          onMouseLeave={() => setHoverOn(false)}
        >
          <Image
            width="100%"
            objectFit="cover"
            minHeight={isMobileWidth ? "220px" : "400px"}
            isBackground
            image={data.featureImage?.asset?.gatsbyImageData}
          />
          <Container width="82%" margin="0 auto" zIndex={2} flexDirection="column" justifyContent="flex-end">
            {data.recipeCategories && data.recipeCategories[0] && (
              <A href={`recipes/${data.slug?.current}`} color="white">
                {typeof data.recipeCategories[0] === "string"
                  ? data.recipeCategories[0]
                  : data.recipeCategories[0].name?.toUpperCase()}
              </A>
            )}
            {data && data.recipeName && (
              <A href={`/recipes/${data?.slug?.current}`}>
                <H4 margin="5px 0 0 0" color="white">
                  {data.recipeName}
                </H4>
              </A>
            )}

            <TimeDetails hoverOn={hoverOn || !isLaptopWidth}>
              {data.prepTime && (
                <Container margin="0 12px 0 0">
                  <Icon src={icons.prepTime} />
                  <P noMargin color="white">
                    {`${data.prepTime} min prep`}
                  </P>
                </Container>
              )}
              {data.cookTime && (
                <Container>
                  <Icon src={icons.cookTime} />
                  <P noMargin color="white">{`${data.cookTime} min cook`}</P>
                </Container>
              )}
              {!data.cookTime && data.cookTimeText && (
                <Container>
                  <Icon src={icons.cookTime} />
                  <P noMargin color="white">{`${data.cookTimeText} cook`}</P>
                </Container>
              )}
            </TimeDetails>
          </Container>
          <Gradient hoverEffectOn={hoverOn} gradient={gradients.gradientFromBottom} />
        </Container>
      </LinkContainer>
    </Container>
  );
}

export default RecipeCard;
